@import '../conf';

.atrakcje {
}
.atrakcje > .container {
    margin-top: -400px;
    min-height: 600px;
}

.atrakcje__heading {
    text-align: center;
}

.atrakcje__articles {
    display: flex;
    flex-direction: column;
}


.atrakcja {
    background: $color-white;
    box-shadow: 0 5px 35px rgba($color-content, .075);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    max-width: 380px;
    margin: .6rem auto 2.3rem;
}
.atrakcja__photo {
    line-height: 0;

    img {
        border-radius: $border-radius;
    }
}
.atrakcja__desc {
    color: $color-heading;
    display: flex;
    flex-direction: column;
    flex: 1;
}
.atrakcja__heading {
    @include rfs(24);
    color: $color-heading;
    font-weight: $font-w-heavy;
    margin: 1.8rem 0 1.2rem;
}
.atrakcja__more {
    @include rfs(14);
    color: $color-primary;
    display: inline-block;
    font-weight: $font-w-semibold;
    margin-top: auto;
    padding-top: 1.3rem;
    transition: color $transition $ease;

    &:focus,
    &:hover {
        color: $color-primary-light;
    }
}


@media screen and (min-width: 768px) {
    .atrakcje__articles {
        flex-direction: row;
        justify-content: space-around;
    }

    .atrakcja {
        width: 33%;
        margin: .6rem 15px 2.3rem;

        &:nth-child(3n+1) {
            margin-left: 0;
        }

        &:nth-child(3n+3) {
            margin-right: 0;
        }
    }
}